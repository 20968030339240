import React, { SetStateAction, useState } from 'react'

import * as s from './styles'
import { bffv2URLBankSlipSettings, post } from 'services/api'
import { showToast } from 'components/Toast'
import SuccessAddingBankSlip from './SuccessAddingBankSlip'
import { isNotEmpty } from 'helpers/validators'
import {
  applyBankAccountItauMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'

interface BankSlipSettingsFields {
  key: string
  maxLength?: number
  friendlyName: string
  valueType: string
  value?: string
  options?: Array<string>
  inputType: string
  placeholder?: string
  validators?: Array<string>
  info?: string
}

interface IProps {
  walletSettingsId: string
  pspProvider: string
  walletFriendlyName: string
  walletIcon: string
  name: string
  bankSlipSettingsField?: Array<BankSlipSettingsFields>
  setHasBankSlip: React.Dispatch<SetStateAction<boolean>>
  goBack: (isFromSuccess: boolean) => void
}

interface FormInfoError {
  title: string
  message: React.ReactElement
}

interface FormData {
  bank_branch?: string
  bank_account?: string
  wallet_code?: string
  recipient_id?: string
}

const INITIAL_INFO_ERROR_DATA: FormInfoError = { title: '', message: <></> }

const INITIAL_FORM_ERROR_DATA: FormData = {
  bank_branch: '',
  bank_account: '',
  wallet_code: '',
  recipient_id: '',
}

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

function removeEmptyKey(form: FormData) {
  return Object.keys(form).forEach(
    (key) => form[key] === '' && delete form[key]
  )
}

const AddBankSlipDefault: React.FC<IProps> = ({
  walletSettingsId,
  pspProvider,
  name,
  walletIcon,
  walletFriendlyName,
  bankSlipSettingsField,
  setHasBankSlip,
  goBack,
}) => {
  const [collapsedSeeMore, setCollapsedSeeMore] = React.useState(true)
  const [formErrors, setFormErrors] = React.useState<FormData>(
    INITIAL_FORM_ERROR_DATA
  )
  const [infoError, setInfoError] = React.useState<FormInfoError>(
    INITIAL_INFO_ERROR_DATA
  )
  const [selectedWalletCode, setSelectWalletCode] = useState({
    id: '',
    name: '',
  })
  const [form, setForm] = React.useState<FormData>({
    bank_branch: '',
    bank_account: '',
    wallet_code: '',
    recipient_id: '',
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccessful, setIsSuccessful] = React.useState(false)

  const infoErrorHandler = (type, errors) => {
    let filteredErrors = {}
    let friendlyNames = {}
    bankSlipSettingsField.forEach((field) => {
      filteredErrors[field.key] = errors[field.key]
      friendlyNames[field.key] = field.friendlyName
    })

    setFormErrors(filteredErrors)
    const fieldsTranslated = Object.entries(filteredErrors)
      .map((error) => (error[1] !== '' ? friendlyNames[error[0]] : undefined))
      .filter((error) => error)
    if (type === 'emptyFields') {
      let requiredFields = ''
      if (fieldsTranslated.length > 2) {
        requiredFields = `${fieldsTranslated
          .slice(0, -1)
          .join(', ')} e ${fieldsTranslated.slice(-1)}`
      } else if (fieldsTranslated.length === 2) {
        requiredFields = `${fieldsTranslated.join(' e ')}`
      } else {
        requiredFields = fieldsTranslated[0]
      }
      setInfoError({
        title: 'Campo(s) obrigatório(s) não preenchido(s)',
        message: (
          <>
            Preencha o(s) campo(s) de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }
  }

  const addBankSlip = () => {
    setIsLoading(true)
    const errors = { ...formErrors }
    for (const [key, value] of Object.entries(form)) {
      if (
        !errors[key] &&
        bankSlipSettingsField.some((field) => field.key === key)
      )
        errors[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      removeEmptyKey(form)
      const payload = {
        psp_provider: pspProvider,
        wallet_settings_id: walletSettingsId,
        bank_slip_settings: {
          psp_provider: pspProvider,
          ...form,
        },
      }

      post(bffv2URLBankSlipSettings, payload)
        .then(() => {
          setIsSuccessful(true)
          setHasBankSlip(true)
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message:
              e.response?.data?.detail ||
              e.response?.data?.message ||
              'Erro na API, tente novamente mais tarde',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      infoErrorHandler('emptyFields', errors)
      setIsLoading(false)
    }
  }

  const applyFieldMask = (field, value) => {
    if (field.validators) {
      if (field.validators.includes('isValidCpfCnpj'))
        return applyCpfCnpjMask(value)
      if (field.validators.includes('isValidCnpj')) return applyCnpjMask(value)
      if (field.validators.includes('isValidPhone'))
        return applyPhoneMask(value)
      if (field.validators.includes('isNumberOnly'))
        return applyNumberOnlyMask(value)
      if (field.validators.includes('isBankAccountItau'))
        return applyBankAccountItauMask(value)
    }
    return value
  }

  const InputElement: React.FC<{
    key: string
    friendlyName: string
    valueType: string
    value: string
    options: Array<string>
    inputType: string
    placeholder: string
    maxLength: number
    validators: string[]
  }> = (field) => {
    switch (field.inputType) {
      case 'text':
        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.InputText
              placeholder={field.placeholder}
              maxLength={field.maxLength ?? undefined}
              testId={`${field.key}-test`}
              width={260}
              value={form[field.key] || ''}
              onChange={(e) => {
                setForm({
                  ...form,
                  [field.key]: applyFieldMask(field, e.target.value),
                })
                if (formErrors[field.key]) {
                  setFormErrors({ ...formErrors, [field.key]: '' })
                }
              }}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              suffix={
                formErrors[field.key] ? (
                  <s.Icon name="alertcirclefilled" fill="lightred2" />
                ) : (
                  <></>
                )
              }
            />
          </s.SectionLine>
        )

      case 'select':
        let list = []
        if (field.options.length > 0) {
          list = field.options.map((value, index) => {
            return {
              id: String(index + 1),
              text: value,
              onClick: () => {
                setSelectWalletCode({ id: String(index + 1), name: value })
              },
            }
          })
        }

        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.Select
              optionBoxSize={150}
              width={260}
              testId={`${field.key}-test`}
              optionBoxDirection="top"
              options={list}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              selected={selectedWalletCode}
              onSelectedOption={(selectedOption) => {
                if (selectedOption.id) {
                  setForm({
                    ...form,
                    [field.key]: applyFieldMask(field, selectedOption.text),
                  })
                  if (formErrors[field.key]) {
                    setFormErrors({ ...formErrors, [field.key]: '' })
                  }
                }
              }}
            />
          </s.SectionLine>
        )

      default:
        return <></>
    }
  }

  return (
    <>
      {isSuccessful ? (
        <SuccessAddingBankSlip
          walletFriendlyName={walletFriendlyName}
          goToEditWS={() => goBack(true)}
        />
      ) : (
        <>
          <s.SectionsWithButton>
            <InfoBox error={infoError} />
            <s.Section data-testid="provider-section">
              <s.ProviderContainer>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  margin="0 118px 0 0"
                  color="graytheme8"
                >
                  Provedor Pix
                </s.Text>
                <img src={walletIcon} width={34} />
                <s.Text
                  bold
                  type="headline"
                  margin="0 0 0 8px"
                  color="graytheme8"
                >
                  {walletFriendlyName}
                </s.Text>
              </s.ProviderContainer>
              <s.SectionLine>
                <s.Text fontWeight={600} type="headline" color="graytheme6">
                  Apelido da conta
                </s.Text>
                <s.InputText
                  testId="name-input"
                  width={260}
                  value={name}
                  disabled={true}
                  onChange={(e) => {}}
                />
              </s.SectionLine>
            </s.Section>
            <s.Line />
            <s.Section data-testid="create-bank-slip-section">
              <s.SeeMoreWrapper>
                <s.TitleWrapper>
                  <s.Icon name="foursquare" fill="gray1" />
                  <s.Text type="headline" color="graytheme6">
                    Boleto Híbrido
                  </s.Text>
                </s.TitleWrapper>
                <s.Text margin="8px 0 0 0" type="tag" color="graytheme8">
                  Gere cobranças com Boleto e Pix ao mesmo tempo. Verifique a
                  disponibilidade no seu sistema.{' '}
                  <s.SeeMoreButton
                    data-testid="see-more-button-optional-feature"
                    onClick={() => setCollapsedSeeMore(!collapsedSeeMore)}
                  >
                    {collapsedSeeMore ? 'ver mais' : 'ocultar'}
                  </s.SeeMoreButton>
                </s.Text>
                {!collapsedSeeMore ? (
                  <s.Text
                    data-testid="see-more-optional-text"
                    fontWeight={400}
                    type="paragraph"
                    color="graytheme8"
                    margin="16px 0 0 0"
                  >
                    Registre boletos híbridos para cobrar seus clientes com um
                    instrumento de cobrança que contém tanto um boleto, quanto
                    um QR Code Pix. Receba direto na sua conta bancária
                    cadastrada na Shipay. Caso o instrumento seja pago com Pix,
                    o boleto é cancelado e vice-versa.
                  </s.Text>
                ) : (
                  <></>
                )}
              </s.SeeMoreWrapper>
              {bankSlipSettingsField.map((field) => {
                return (
                  <React.Fragment key={field.key}>
                    {InputElement({
                      friendlyName: field.friendlyName,
                      inputType: field.inputType,
                      key: field.key,
                      value: field.value,
                      options: field.options,
                      maxLength: field.maxLength,
                      placeholder: field.placeholder,
                      valueType: field.valueType,
                      validators: field.validators,
                    })}
                  </React.Fragment>
                )
              })}
            </s.Section>
          </s.SectionsWithButton>
          <s.PreviousNextButtonContainer>
            <s.Div></s.Div>
            <s.PreviousNextButtonWrapper>
              <s.Button
                data-testid="back-button"
                onClick={() => {
                  goBack(false)
                }}
                width="304px"
                height="42px"
                color="whitedetailed"
              >
                <s.Text color="maincolor" type="headline">
                  Cancelar
                </s.Text>
              </s.Button>
              <s.Button
                data-testid="add-bank-slip-button"
                width="304px"
                height="42px"
                onClick={() => {
                  addBankSlip()
                }}
                disabled={isLoading}
              >
                {isLoading ? <s.Loading color="white" type="spinner" /> : <></>}
                <s.Text margin="0 8px 0 0" color="white" type="headline">
                  Adicionar
                </s.Text>
              </s.Button>
            </s.PreviousNextButtonWrapper>
          </s.PreviousNextButtonContainer>
        </>
      )}
    </>
  )
}

export default AddBankSlipDefault
